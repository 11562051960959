import React from "react";
import css from "./HomeBanner.module.css";
import lightning from "../img/lightning-charge.svg";

const HomeBanner = () => {
  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      className={css.banner}
      href="https://vesting.laosnetwork.io"
      target="_self"
    >
      <img
        src={lightning}
        width="16"
        height="16"
        alt="LAOS Network Wallet Compatibility Roadmap
        "
      />{" "}
      <span data-testid="banner-text">
        Pre-sale token-distribution completed! Click here to view the status of purchased tokens.
      </span>
    </a>
  );
};

export default HomeBanner;
