import React, { useState } from "react";
import laos_logo from "../img/LAOS_logo.png";
import HomeBanner from "./HomeBanner";

const Header = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showResourcesDesktop, setShowResourcesDesktop] = useState(false);
  const [showResourcesMobile, setShowResourcesMobile] = useState(false);
  const [showDocsDesktop, setShowDocsDesktop] = useState(false);
  const [showDocsMobile, setShowDocsMobile] = useState(false);
  const [showTokenDesktop, setShowTokenDesktop] = useState(false);
  const [showTokenMobile, setShowTokenMobile] = useState(false);

  const clickShowResourcesDesktop = () => {
    setShowDocsDesktop(false);
    setShowTokenDesktop(false);
    setShowResourcesDesktop(!showResourcesDesktop);
  };
  const clickShowResourcesMobile = () => {
    setShowDocsMobile(false);
    setShowTokenMobile(false);
    setShowResourcesMobile(!showResourcesMobile);
  };

  const clickShowDocsDesktop = () => {
    setShowResourcesDesktop(false);
    setShowTokenDesktop(false);
    setShowDocsDesktop(!showDocsDesktop);
  };
  const clickShowDocsMobile = () => {
    setShowResourcesMobile(false);
    setShowTokenMobile(false);
    setShowDocsMobile(!showDocsMobile);
  };

  const clickShowTokenDesktop = () => {
    setShowResourcesDesktop(false);
    setShowDocsDesktop(false);
    setShowTokenDesktop(!showTokenDesktop);
  };
  const clickShowTokenMobile = () => {
    setShowResourcesMobile(false);
    setShowDocsMobile(false);
    setShowTokenMobile(!showTokenMobile);
  };

  return (
    <header
      className="px-10 py-6  mt-10"
      style={{ zIndex: 1000, position: "relative" }}
    >
      <HomeBanner />
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-4">
          <a href="/">
            <img src={laos_logo} alt="LAOS Logo" className="h-20" />
          </a>
          <div>
            <div className="header-title tracking-widest text-fvyellow text-5xl">
              LAOS
            </div>
            <div className="header-title tracking-widest text-white text-xs">
              The home of digital assets
            </div>
          </div>
        </div>
        <div className="hamburger flex lg:hidden">
          <button
            onClick={() => setShowMobileMenu(!showMobileMenu)}
            className="text-white text-3xl focus:outline-none"
          >
            <i className="fas fa-bars"></i>
          </button>
        </div>
        {/* <!-- Desktop Navigation --> */}
        <nav className="nav-links lg:flex hidden items-center space-x-8 text-sm">
          {/* <a
            href="/crowdloan"
            className="text-white hover:underline transition duration-300 ease-in-out"
          >
            Crowdloan
          </a> */}
          <div className="relative">
            <button
              onClick={clickShowTokenDesktop}
              className="text-white hover:underline transition duration-300 ease-in-out"
              href="#"
            >
              Ecosystem
            </button>
            <div
              onClick={clickShowTokenDesktop}
              className="text-white hover:underline inline-block w-[14px] h-auto ml-[10px]"
            >
              <svg data-name="Layer 1" viewBox="0 0 24 14.15">
                <path
                  d="m12 14.15-12-12L2.15 0 12 9.9 21.85.05 24 2.2 12 14.15Z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
            <div
              className={`menu-resources ${
                showTokenDesktop ? "" : "hidden"
              } block absolute mt-0 ml-0 min-w-[280px] w-max top-[calc(100%+20px)] bg-fvlightgrey py-[24px] px-[20px] rounded-2xl`}
            >
              
              <div className="text-fvpurple flex mb-3">
                <div className="w-[34px] h-auto inline-block">
                  <svg
                    className="border-2 border-fvpurple rounded inline"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 36 36"
                  >
                    <path
                      fill="currentColor"
                      d="M19.125 14.625V4.5H31.5v10.125H19.125ZM4.5 19.125V4.5h12.375v14.625H4.5ZM19.125 31.5V16.875H31.5V31.5H19.125ZM4.5 31.5V21.375h12.375V31.5H4.5Zm2.25-14.625h7.875V6.75H6.75v10.125ZM21.375 29.25h7.875V19.125h-7.875V29.25Zm0-16.875h7.875V6.75h-7.875v5.625ZM6.75 29.25h7.875v-5.625H6.75v5.625Z"
                    ></path>
                  </svg>
                </div>
                <div className="inline-block">
                  <a
                    href="https://vesting.laosnetwork.io"
                    className="block ml-2 mt-1 text-base text-fvpurple"
                  >
                    Token Status
                  </a>
                </div>
              
              </div>
              <div className="text-fvpurple flex mb-3">
                <div className="w-[34px] h-auto inline-block">
                  <svg
                    className="border-2 border-fvpurple rounded inline"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 36 36"
                  >
                    <path
                      fill="currentColor"
                      d="M8.25 33c-.6 0-1.125-.225-1.575-.675-.45-.45-.675-.975-.675-1.575V5.25c0-.6.225-1.125.675-1.575C7.125 3.225 7.65 3 8.25 3h13.538L30 11.213V30.75c0 .6-.225 1.125-.675 1.575-.45.45-.975.675-1.575.675H8.25Zm12.413-20.775V5.25H8.25v25.5h19.5V12.225h-7.087Z"
                    ></path>
                  </svg>
                </div>
                <div className="inline-block">
                  <a
                    href="/crowdloan"
                    className="block ml-2 mt-1 text-base text-fvpurple"
                  >
                    Crowdloan
                  </a>
                </div>
              </div>
              <div className="text-fvpurple flex mb-3">
                <div className="w-[34px] h-auto inline-block">
                  <svg
                    className="border-2 border-fvpurple rounded inline"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 36 36"
                  >
                    <path
                      fill="currentColor"
                      d="M11.963 26.625h12.075v-2.25H11.963v2.25Zm0-6.375h12.075V18H11.963v2.25ZM8.25 33c-.6 0-1.125-.225-1.575-.675-.45-.45-.675-.975-.675-1.575V5.25c0-.6.225-1.125.675-1.575C7.125 3.225 7.65 3 8.25 3h13.538L30 11.213V30.75c0 .6-.225 1.125-.675 1.575-.45.45-.975.675-1.575.675H8.25Zm12.413-20.775V5.25H8.25v25.5h19.5V12.225h-7.087Z"
                    ></path>
                  </svg>
                </div>
                <div className="inline-block">
                  <a
                    href="/wallet-compatibility-roadmap"
                    className="block ml-2 mt-1 text-base text-fvpurple"
                  >
                    Wallet Compatibility
                  </a>
                </div>
              </div>
              <div className="text-fvpurple flex mb-0">
                <div className="w-[34px] h-auto inline-block">
                  <svg
                    className="border-2 border-fvpurple rounded inline"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 36 36"
                  >
                    <path
                      fill="currentColor"
                      d="M11.963 26.625h12.075v-2.25H11.963v2.25Zm0-6.375h12.075V18H11.963v2.25ZM8.25 33c-.6 0-1.125-.225-1.575-.675-.45-.45-.675-.975-.675-1.575V5.25c0-.6.225-1.125.675-1.575C7.125 3.225 7.65 3 8.25 3h13.538L30 11.213V30.75c0 .6-.225 1.125-.675 1.575-.45.45-.975.675-1.575.675H8.25Zm12.413-20.775V5.25H8.25v25.5h19.5V12.225h-7.087Z"
                    ></path>
                  </svg>
                </div>
                <div className="inline-block">
                  <a
                    href="/roadmap-after-tge"
                    className="block ml-2 mt-1 text-base text-fvpurple"
                  >
                    Roadmap After TGE
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="relative">
            <button
              onClick={clickShowResourcesDesktop}
              className="text-white hover:underline transition duration-300 ease-in-out"
              href="#"
            >
              Resources
            </button>
            <div
              onClick={clickShowResourcesDesktop}
              className="text-white hover:underline inline-block w-[14px] h-auto ml-[10px]"
            >
              <svg data-name="Layer 1" viewBox="0 0 24 14.15">
                <path
                  d="m12 14.15-12-12L2.15 0 12 9.9 21.85.05 24 2.2 12 14.15Z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
            <div
              className={`menu-resources ${
                showResourcesDesktop ? "" : "hidden"
              } block absolute mt-0 ml-0 min-w-[280px] w-max top-[calc(100%+20px)] bg-fvlightgrey py-[24px] px-[20px] rounded-2xl`}
            >
              <div className="text-fvpurple flex mb-3">
                <div className="w-[34px] h-auto inline-block">
                  <svg
                    className="border-2 border-fvpurple rounded inline"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 36 36"
                  >
                    <path
                      fill="currentColor"
                      d="M8.25 33c-.6 0-1.125-.225-1.575-.675-.45-.45-.675-.975-.675-1.575V5.25c0-.6.225-1.125.675-1.575C7.125 3.225 7.65 3 8.25 3h13.538L30 11.213V30.75c0 .6-.225 1.125-.675 1.575-.45.45-.975.675-1.575.675H8.25Zm12.413-20.775V5.25H8.25v25.5h19.5V12.225h-7.087Z"
                    ></path>
                  </svg>
                </div>
                <div className="inline-block">
                  <a
                    href={
                      process.env.PUBLIC_URL + "/downloads/LAOS_litepaper.pdf"
                    }
                    className="block ml-2 mt-1 text-base text-fvpurple"
                  >
                    Litepaper
                  </a>
                </div>
              </div>
              <div className="text-fvpurple flex mb-3">
                <div className="w-[34px] h-auto inline-block">
                  <svg
                    className="border-2 border-fvpurple rounded inline"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 36 36"
                  >
                    <path
                      fill="currentColor"
                      d="M11.963 26.625h12.075v-2.25H11.963v2.25Zm0-6.375h12.075V18H11.963v2.25ZM8.25 33c-.6 0-1.125-.225-1.575-.675-.45-.45-.675-.975-.675-1.575V5.25c0-.6.225-1.125.675-1.575C7.125 3.225 7.65 3 8.25 3h13.538L30 11.213V30.75c0 .6-.225 1.125-.675 1.575-.45.45-.975.675-1.575.675H8.25Zm12.413-20.775V5.25H8.25v25.5h19.5V12.225h-7.087Z"
                    ></path>
                  </svg>
                </div>
                <div className="inline-block">
                  <a
                    href="https://github.com/freeverseio/laos-whitepaper/blob/main/laos.pdf"
                    className="block ml-2 mt-1 text-base text-fvpurple"
                  >
                    Technical Whitepaper
                  </a>
                </div>
              </div>
              <div className="text-fvpurple flex">
                <div className="w-[34px] h-auto inline-block">
                  <svg
                    className="border-2 border-fvpurple rounded inline"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 36 36"
                  >
                    <path
                      fill="currentColor"
                      d="M19.125 14.625V4.5H31.5v10.125H19.125ZM4.5 19.125V4.5h12.375v14.625H4.5ZM19.125 31.5V16.875H31.5V31.5H19.125ZM4.5 31.5V21.375h12.375V31.5H4.5Zm2.25-14.625h7.875V6.75H6.75v10.125ZM21.375 29.25h7.875V19.125h-7.875V29.25Zm0-16.875h7.875V6.75h-7.875v5.625ZM6.75 29.25h7.875v-5.625H6.75v5.625Z"
                    ></path>
                  </svg>
                </div>
                <div className="inline-block">
                  <a
                    href="https://laos.gitbook.io/laos-whitepaper/"
                    className="block ml-2 mt-1 text-base text-fvpurple"
                  >
                    Tokenomics
                  </a>
                </div>
              </div>
            </div>
          </div>
          <a
            href="https://medium.com/laosnetwork"
            className="text-white hover:underline transition duration-300 ease-in-out"
          >
            Blog
          </a>
          <div className="relative">
            <a
              onClick={clickShowDocsDesktop}
              href="#"
              className="text-white hover:underline transition duration-300 ease-in-out"
            >
              Developers
            </a>
            <div
              onClick={clickShowDocsDesktop}
              className="text-white hover:underline inline-block w-[14px] h-auto ml-[10px]"
            >
              <svg data-name="Layer 1" viewBox="0 0 24 14.15">
                <path
                  d="m12 14.15-12-12L2.15 0 12 9.9 21.85.05 24 2.2 12 14.15Z"
                  fill="currentColor"
                ></path>
              </svg>
            </div>
            <div
              className={`menu-resources ${
                showDocsDesktop ? "" : "hidden"
              } block absolute mt-0 ml-0 min-w-[280px] w-max top-[calc(100%+20px)] bg-fvlightgrey py-[24px] px-[20px] rounded-2xl`}
            >
              <div className="text-fvpurple flex mb-3">
                <div className="w-[34px] h-auto inline-block">
                  <svg
                    className="border-2 border-fvpurple rounded inline"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 36 36"
                  >
                    <path
                      fill="currentColor"
                      d="M8.25 33c-.6 0-1.125-.225-1.575-.675-.45-.45-.675-.975-.675-1.575V5.25c0-.6.225-1.125.675-1.575C7.125 3.225 7.65 3 8.25 3h13.538L30 11.213V30.75c0 .6-.225 1.125-.675 1.575-.45.45-.975.675-1.575.675H8.25Zm12.413-20.775V5.25H8.25v25.5h19.5V12.225h-7.087Z"
                    ></path>
                  </svg>
                </div>
                <div className="inline-block">
                  <a
                    href="https://docs.laosnetwork.io/"
                    className="block ml-2 mt-1 text-base text-fvpurple"
                  >
                    Documentation
                  </a>
                </div>
              </div>
              <div className="text-fvpurple flex mb-">
                <div className="w-[34px] h-auto inline-block">
                  <svg
                    className="border-2 border-fvpurple rounded inline"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 36 36"
                  >
                    <path
                      fill="currentColor"
                      d="M11.963 26.625h12.075v-2.25H11.963v2.25Zm0-6.375h12.075V18H11.963v2.25ZM8.25 33c-.6 0-1.125-.225-1.575-.675-.45-.45-.675-.975-.675-1.575V5.25c0-.6.225-1.125.675-1.575C7.125 3.225 7.65 3 8.25 3h13.538L30 11.213V30.75c0 .6-.225 1.125-.675 1.575-.45.45-.975.675-1.575.675H8.25Zm12.413-20.775V5.25H8.25v25.5h19.5V12.225h-7.087Z"
                    ></path>
                  </svg>
                </div>
                <div className="inline-block">
                  <a
                    href="https://github.com/freeverseio/laos"
                    className="block ml-2 mt-1 text-base text-fvpurple"
                  >
                    Github
                  </a>
                </div>
              </div>
            </div>
          </div>
          <a
            href="/grant-program/"
            className="text-white hover:underline transition duration-300 ease-in-out"
          >
            Grants
          </a>
          <a
            href="/about/"
            className="text-white hover:underline transition duration-300 ease-in-out"
          >
            About
          </a>
          <a
            href="/#community"
            className="border border-fvyellow bg-fvyellow text-fvpurple px-4 py-2 rounded transition duration-300 ease-in-out hover:bg-transparent hover:text-fvyellow"
          >
            Join Community
          </a>
        </nav>
      </div>
      {/* <!-- Mobile Navigation --> */}
      <div
        className={
          showMobileMenu
            ? "mobile-nav lg:hidden mb-[128px]"
            : "mobile-nav hidden lg:hidden mb-[128px]"
        }
      >
        <div className="text-white hover:underline transition duration-300 ease-in-out block py-2">
          <div className="inline" onClick={clickShowTokenMobile}>
            Ecosystem
          </div>
          <div
            onClick={clickShowTokenMobile}
            className="inline-block text-white hover:underline inline-block w-[14px] h-auto ml-[10px]"
          >
            <svg data-name="Layer 1" viewBox="0 0 24 14.15">
              <path
                d="m12 14.15-12-12L2.15 0 12 9.9 21.85.05 24 2.2 12 14.15Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div
            className={`menu-resources-mobile ${
              showTokenMobile ? "" : "hidden"
            } block mt-0 ml-0 min-w-[280px] w-max top-[calc(100%+20px)] bg-fvlightgrey py-[24px] px-[20px] rounded-2xl`}
          >
            
            <div className="text-fvpurple flex mb-3">
              <div className="w-[34px] h-auto inline-block">
                <svg
                  className="border-2 border-fvpurple rounded inline"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 36 36"
                >
                  <path
                    fill="currentColor"
                    d="M8.25 33c-.6 0-1.125-.225-1.575-.675-.45-.45-.675-.975-.675-1.575V5.25c0-.6.225-1.125.675-1.575C7.125 3.225 7.65 3 8.25 3h13.538L30 11.213V30.75c0 .6-.225 1.125-.675 1.575-.45.45-.975.675-1.575.675H8.25Zm12.413-20.775V5.25H8.25v25.5h19.5V12.225h-7.087Z"
                  ></path>
                </svg>
              </div>
              <div className="inline-block">
                <a
                  href="https://vesting.laosnetwork.io"
                  className="block ml-2 mt-1 text-base text-fvpurple"
                >
                  Token Status
                </a>
              </div>
            </div>
            <div className="text-fvpurple flex mb-3">
              <div className="w-[34px] h-auto inline-block">
                <svg
                  className="border-2 border-fvpurple rounded inline"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 36 36"
                >
                  <path
                    fill="currentColor"
                    d="M8.25 33c-.6 0-1.125-.225-1.575-.675-.45-.45-.675-.975-.675-1.575V5.25c0-.6.225-1.125.675-1.575C7.125 3.225 7.65 3 8.25 3h13.538L30 11.213V30.75c0 .6-.225 1.125-.675 1.575-.45.45-.975.675-1.575.675H8.25Zm12.413-20.775V5.25H8.25v25.5h19.5V12.225h-7.087Z"
                  ></path>
                </svg>
              </div>
              <div className="inline-block">
                <a
                  href="/crowdloan"
                  className="block ml-2 mt-1 text-base text-fvpurple"
                >
                  Crowdloan
                </a>
              </div>
            </div>
            <div className="text-fvpurple flex mb-3">
              <div className="w-[34px] h-auto inline-block">
                <svg
                  className="border-2 border-fvpurple rounded inline"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 36 36"
                >
                  <path
                    fill="currentColor"
                    d="M11.963 26.625h12.075v-2.25H11.963v2.25Zm0-6.375h12.075V18H11.963v2.25ZM8.25 33c-.6 0-1.125-.225-1.575-.675-.45-.45-.675-.975-.675-1.575V5.25c0-.6.225-1.125.675-1.575C7.125 3.225 7.65 3 8.25 3h13.538L30 11.213V30.75c0 .6-.225 1.125-.675 1.575-.45.45-.975.675-1.575.675H8.25Zm12.413-20.775V5.25H8.25v25.5h19.5V12.225h-7.087Z"
                  ></path>
                </svg>
              </div>
              <div className="inline-block">
                <a
                  href="/wallet-compatibility-roadmap"
                  className="block ml-2 mt-1 text-base text-fvpurple"
                >
                  Wallet Compatibility
                </a>
              </div>
            </div>
            <div className="text-fvpurple flex mb-3">
              <div className="w-[34px] h-auto inline-block">
                <svg
                  className="border-2 border-fvpurple rounded inline"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 36 36"
                >
                  <path
                    fill="currentColor"
                    d="M11.963 26.625h12.075v-2.25H11.963v2.25Zm0-6.375h12.075V18H11.963v2.25ZM8.25 33c-.6 0-1.125-.225-1.575-.675-.45-.45-.675-.975-.675-1.575V5.25c0-.6.225-1.125.675-1.575C7.125 3.225 7.65 3 8.25 3h13.538L30 11.213V30.75c0 .6-.225 1.125-.675 1.575-.45.45-.975.675-1.575.675H8.25Zm12.413-20.775V5.25H8.25v25.5h19.5V12.225h-7.087Z"
                  ></path>
                </svg>
              </div>
              <div className="inline-block">
                <a
                  href="/roadmap-after-tge"
                  className="block ml-2 mt-1 text-base text-fvpurple"
                >
                  Roadmap After TGE
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="text-white hover:underline transition duration-300 ease-in-out block py-2">
          <div className="inline" onClick={clickShowResourcesMobile}>
            Resources
          </div>
          <div
            onClick={clickShowResourcesMobile}
            className="inline-block text-white hover:underline inline-block w-[14px] h-auto ml-[10px]"
          >
            <svg data-name="Layer 1" viewBox="0 0 24 14.15">
              <path
                d="m12 14.15-12-12L2.15 0 12 9.9 21.85.05 24 2.2 12 14.15Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div
            className={`menu-resources-mobile ${
              showResourcesMobile ? "" : "hidden"
            } block mt-0 ml-0 min-w-[280px] w-max top-[calc(100%+20px)] bg-fvlightgrey py-[24px] px-[20px] rounded-2xl`}
          >
            <div className="text-fvpurple flex mb-3">
              <div className="w-[34px] h-auto inline-block">
                <svg
                  className="border-2 border-fvpurple rounded inline"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 36 36"
                >
                  <path
                    fill="currentColor"
                    d="M8.25 33c-.6 0-1.125-.225-1.575-.675-.45-.45-.675-.975-.675-1.575V5.25c0-.6.225-1.125.675-1.575C7.125 3.225 7.65 3 8.25 3h13.538L30 11.213V30.75c0 .6-.225 1.125-.675 1.575-.45.45-.975.675-1.575.675H8.25Zm12.413-20.775V5.25H8.25v25.5h19.5V12.225h-7.087Z"
                  ></path>
                </svg>
              </div>
              <div className="inline-block">
                <a
                  href="../downloads/LAOS_litepaper.pdf"
                  className="block ml-2 mt-1 text-base text-fvpurple"
                >
                  Litepaper
                </a>
              </div>
            </div>
            <div className="text-fvpurple flex mb-3">
              <div className="w-[34px] h-auto inline-block">
                <svg
                  className="border-2 border-fvpurple rounded inline"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 36 36"
                >
                  <path
                    fill="currentColor"
                    d="M11.963 26.625h12.075v-2.25H11.963v2.25Zm0-6.375h12.075V18H11.963v2.25ZM8.25 33c-.6 0-1.125-.225-1.575-.675-.45-.45-.675-.975-.675-1.575V5.25c0-.6.225-1.125.675-1.575C7.125 3.225 7.65 3 8.25 3h13.538L30 11.213V30.75c0 .6-.225 1.125-.675 1.575-.45.45-.975.675-1.575.675H8.25Zm12.413-20.775V5.25H8.25v25.5h19.5V12.225h-7.087Z"
                  ></path>
                </svg>
              </div>
              <div className="inline-block">
                <a
                  href="https://github.com/freeverseio/laos-whitepaper/blob/main/laos.pdf"
                  className="block ml-2 mt-1 text-base text-fvpurple"
                >
                  Technical Whitepaper
                </a>
              </div>
            </div>
            <div className="text-fvpurple flex">
                <div className="w-[34px] h-auto inline-block">
                  <svg
                    className="border-2 border-fvpurple rounded inline"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 36 36"
                  >
                    <path
                      fill="currentColor"
                      d="M19.125 14.625V4.5H31.5v10.125H19.125ZM4.5 19.125V4.5h12.375v14.625H4.5ZM19.125 31.5V16.875H31.5V31.5H19.125ZM4.5 31.5V21.375h12.375V31.5H4.5Zm2.25-14.625h7.875V6.75H6.75v10.125ZM21.375 29.25h7.875V19.125h-7.875V29.25Zm0-16.875h7.875V6.75h-7.875v5.625ZM6.75 29.25h7.875v-5.625H6.75v5.625Z"
                    ></path>
                  </svg>
                </div>
                <div className="inline-block">
                  <a
                    href="https://laos.gitbook.io/laos-whitepaper/"
                    className="block ml-2 mt-1 text-base text-fvpurple"
                  >
                    Tokenomics
                  </a>
                </div>
              </div>
          </div>
        </div>

        <a
          href="/blog/"
          className="text-white hover:underline transition duration-300 ease-in-out block py-2"
        >
          Blog
        </a>
        <div className="text-white hover:underline transition duration-300 ease-in-out block py-2">
          <div className="inline" onClick={clickShowDocsMobile}>
            Developers
          </div>
          <div
            onClick={clickShowDocsMobile}
            className="inline-block text-white hover:underline inline-block w-[14px] h-auto ml-[10px]"
          >
            <svg data-name="Layer 1" viewBox="0 0 24 14.15">
              <path
                d="m12 14.15-12-12L2.15 0 12 9.9 21.85.05 24 2.2 12 14.15Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
          <div
            className={`menu-resources-mobile ${
              showDocsMobile ? "" : "hidden"
            } block mt-0 ml-0 min-w-[280px] w-max top-[calc(100%+20px)] bg-fvlightgrey py-[24px] px-[20px] rounded-2xl`}
          >
            <div className="text-fvpurple flex mb-3">
              <div className="w-[34px] h-auto inline-block">
                <svg
                  className="border-2 border-fvpurple rounded inline"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 36 36"
                >
                  <path
                    fill="currentColor"
                    d="M8.25 33c-.6 0-1.125-.225-1.575-.675-.45-.45-.675-.975-.675-1.575V5.25c0-.6.225-1.125.675-1.575C7.125 3.225 7.65 3 8.25 3h13.538L30 11.213V30.75c0 .6-.225 1.125-.675 1.575-.45.45-.975.675-1.575.675H8.25Zm12.413-20.775V5.25H8.25v25.5h19.5V12.225h-7.087Z"
                  ></path>
                </svg>
              </div>
              <div className="inline-block">
                <a
                  href="https://docs.laosnetwork.io/"
                  className="block ml-2 mt-1 text-base text-fvpurple"
                >
                  Documentation
                </a>
              </div>
            </div>
            <div className="text-fvpurple flex mb-0">
              <div className="w-[34px] h-auto inline-block">
                <svg
                  className="border-2 border-fvpurple rounded inline"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 36 36"
                >
                  <path
                    fill="currentColor"
                    d="M11.963 26.625h12.075v-2.25H11.963v2.25Zm0-6.375h12.075V18H11.963v2.25ZM8.25 33c-.6 0-1.125-.225-1.575-.675-.45-.45-.675-.975-.675-1.575V5.25c0-.6.225-1.125.675-1.575C7.125 3.225 7.65 3 8.25 3h13.538L30 11.213V30.75c0 .6-.225 1.125-.675 1.575-.45.45-.975.675-1.575.675H8.25Zm12.413-20.775V5.25H8.25v25.5h19.5V12.225h-7.087Z"
                  ></path>
                </svg>
              </div>
              <div className="inline-block">
                <a
                  href="https://github.com/freeverseio/laos"
                  className="block ml-2 mt-1 text-base text-fvpurple"
                >
                  Github
                </a>
              </div>
            </div>
          </div>
        </div>
        <a
          href="/grant-program/"
          className="text-white hover:underline transition duration-300 ease-in-out block py-2"
        >
          Grants
        </a>
        <a
          href="/about/"
          className="text-white hover:underline transition duration-300 ease-in-out block py-2"
        >
          About
        </a>
        <a
          href="/#community"
          className="border border-fvyellow bg-fvyellow text-fvpurple px-4 py-2 rounded transition duration-300 ease-in-out block py-2 hover:bg-transparent hover:text-fvyellow"
        >
          Join Community
        </a>
      </div>
    </header>
  );
};

export default Header;
