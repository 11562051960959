import React from 'react';
import icon_X from '../img/x_purple.png';
import icon_LI from '../img/Linkedin_purple.png';
import founder_alun from '../img/about/alun.png';
import founder_toni from '../img/about/toni.png';
import founder_ale from '../img/about/ale.png';
import founder_fer from '../img/about/fer.png';
import logo_cupra from '../img/used_by/cupra_logo.svg';
import logo_hedera from '../img/used_by/hedera_logo.svg';
import logo_hyperreal from '../img/used_by/hyperreal_logo 1.png';
import logo_mediapro from '../img/used_by/mediapro_logo.svg';
import logo_a3 from '../img/used_by/A3Media_logo.svg';
import logo_ntwk from '../img/used_by/ntwk_logo.png';
import logo_sm from '../img/about/used_by/logo_strikermanager3.png';
import logo_eb from '../img/Investors/earlybird_logo.svg';
import logo_tg from '../img/Investors/target_global_logo.svg';
import logo_ad from '../img/Investors/Adara_logo.svg';
import logo_4f from '../img/Investors/4founders_logo.png';
import freeverse_team from '../img/freeverse_team.jpg';

const About = () => {
    return (
        <>
        <section className="sm:container mx-auto px-8 sm:px-20 lg:px-40 pb-10 mb-5">
            <div className="flex flex-col space-y-4 justify-center items-center section-title text-center">
                <div className="text-4xl">Team LAOS</div>
                <div className="text-lg md:text-2xl ">
                    LAOS is founded & promoted by <a className="hover:text-fvpurple underline" href="https://freeverse.io">Freeverse</a>. 
                    Freeverse&apos;s Layer-2, launched in 2021 and focused on dynamic assets, is being used by Cupra, Hyperreal, Mediapro & various video games and brands.
                    Now, we are excited to extend our vision with a universal Layer-1. 
                </div>
            </div>
        </section>
        <div className="background-about-main text-fvdarkpurple">
    <section className="container mx-auto px-8 sm:px-20 lg:px-40 py-10 space-y-4">
        <div className="grid grid-cols-2 md:grid-cols-4 items-center text-center text-fvpurple -mt-24">
            <div className="flex flex-col bg-white rounded-2xl mx-4 p-4 my-4">
                <img src={founder_alun} alt="Alun Evans" />
                <div className="text-lg font-bold h-16">Dr. Alun Evans</div>
                <div className="text-md h-12">CEO</div>
                <div className="flex flex-row justify-center items-center space-x-4 py-4">
                    <a href="https://twitter.com/alunthomasevans"><img src={icon_X} alt="x" /></a>
                    <a href="https://www.linkedin.com/in/alun-evans/"><img src={icon_LI} alt="li" /></a>
                </div>
            </div>
            <div className="flex flex-col bg-white rounded-2xl mx-4 p-4">
                <img src={founder_toni} alt="Toni Mateos" />
                <div className="text-lg font-bold h-16">Dr. Toni Mateos</div>
                <div className="text-md h-12">CTO</div>
                <div className="flex flex-row justify-center items-center space-x-4 py-4">
                    <a href="https://twitter.com/toni_mateos"><img src={icon_X} alt="x" /></a>
                    <a href="https://www.linkedin.com/in/toni--mateos/"><img src={icon_LI} alt="li" /></a>
                </div>
            </div>
            <div className="flex flex-col bg-white rounded-2xl mx-4 p-4">
                <img src={founder_ale} alt="Alessandro Siniscalchi" />
                <div className="text-lg font-bold h-16">Alessandro Siniscalchi</div>
                <div className="text-md h-12">Head of Engineering</div>
                <div className="flex flex-row justify-center items-center space-x-4 py-4">
                    <a href="https://www.linkedin.com/in/asiniscalchi/"><img src={icon_LI} alt="li" /></a>
                </div>
            </div>
            <div className="flex flex-col bg-white rounded-2xl mx-4 p-4">
                <img src={founder_fer} alt="Ferran Estalella" />
                <div className="text-lg font-bold h-16">Ferran Estalella</div>
                <div className="text-md h-12">COO</div>
                <div className="flex flex-row justify-center items-center space-x-4 py-4">        
                    <a href="https://www.linkedin.com/in/fernando-estalella-2433b046/"><img src={icon_LI} alt="li" /></a>
                </div>
            </div>
            
        </div>
    </section> 



    <section className="container mx-auto px-8 sm:px-20 lg:px-40 space-y-4">
        <div className="flex flex-col justify-center items-center text-center">
            <div className="w-1/8 border-2 border-fvyellow text-fvyellow px-12 py-3 rounded-full ">Used By</div>
            <div className="flex flex-row justify-center items-center px-2 md:px-10 space-x-2 md:space-x-20 mt-8">
                <div><img src={logo_cupra} alt="Cupra" /></div>
                <div><img src={logo_hedera} alt="Hedera" /></div>
                <div><img src={logo_hyperreal} alt="Hyperreal" /></div>
            </div>
            <div className="flex flex-row justify-center items-center px-2 md:px-10 space-x-8 md:space-x-20 mt-8">
                <div><img src={logo_mediapro} alt="Mediapro" /></div>
                <div><img src={logo_a3} alt="A3Media" /></div>
                <div><img src={logo_ntwk} alt="Cupra" /></div>
                <div><img src={logo_sm} alt="Striker Manager" /></div>
            </div>
        </div>
        <div className="flex flex-col justify-center items-center py-10 text-center">
            <div className="w-1/8 border-2 border-fvyellow text-fvyellow px-12 py-3 rounded-full ">Investors</div>
            <div className="flex flex-row justify-center items-center px-10 space-x-8 md:space-x-20 mt-8">
                <div><img src={logo_eb} alt="Earlybird" /></div>
                <div><img src={logo_tg} alt="Target Global" /></div>
            </div>
            <div className="flex flex-row justify-center items-center px-10 space-x-8 md:space-x-20 mt-8">
                <div><img src={logo_ad} alt="Adara" /></div>
                <div><img src={logo_4f} alt="4 Founders" /></div>
            </div>
        </div>     
    </section>
</div>
<div className="flex justify-center items-center">
    <div><img src={freeverse_team} alt="Freeverse" /></div>
</div>
        </>
    )
};
  
export default About;