/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import logo_eth from "../img/logo_eth.png";
import logo_pol from "../img/logo_polygon.png";
import logo_bin from "../img/logo_binance.png";
import logo_cron from "../img/logo_cronos.png";
import logo_op from "../img/logo_op.png";
import logo_arb from "../img/logo_arbitrum.png";
import icon_bridgeless from "../img/bridgeless_icon.svg";
import icon_1M from "../img/1mtps_icon.svg";
// import icon_RWA from '../img/RWA_icon.svg';
import icon_DAME from "../img/dame_icon.svg";
import icon_DL from "../img/arrow_download.png";
import logo_early from "../img/earlybird_logo_1.png";
import logo_target from "../img/target_global_logo_1.png";
import logo_adara from "../img/Adara_logo_1.png";
import logo_4F from "../img/4founders_logo_1.png";
import card_RWA from "../img/BLOCK3_2.png";
import card_coll from "../img/BLOCK3_1.png";
import logo_polka from "../img/polkadot_parachain_logo.svg";
import benefit_icon_bridgeless from "../img/icon_bridgeless.png";
import benefit_icon_interop from "../img/icon_interop.png";
import benefit_icon_scale from "../img/icon_scalability.png";
import com_discord from "../img/discord.svg";
import com_x from "../img/x_icon.svg";
import com_tele from "../img/telegram.png";
import com_LI from "../img/Linkedin_Icon.svg";
import com_git from "../img/github_logo.svg";
import com_yt from "../img/Youtube_Icon.svg";
import planet from "../img/planets_community.png";
import roadmap from "../img/LAOS_ROADMAP.png";
// a basic form
const CustomForm = ({ status, message, onValidated }) => {
  let email;
  const submit = () =>
    email &&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
    });

  return (
    <div className="bg-red border-sm pt-2 inline-block">
      {status === "sending" && <div className="text-fvyellow">Sending...</div>}
      {status === "error" && (
        <div
          className="text-red-500"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === "success" && (
        <div
          className="text-green-500"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      <div className="flex flex-col sm:flex-row py-2 space-y-4 sm:space-y-0">
        <input
          className="w-[300px] text-slate-800 w-full rounded-lg mr-3 py-3 px-2 leading-tight"
          ref={(node) => (email = node)}
          type="email"
          placeholder="Your email"
        />
        <button
          className="w-40 flex-shrink-0 border-2 bg-fvyellow border-fvyellow text-fvpurple px-8 py-2 rounded-full transition duration-300 ease-in-out hover:bg-transparent hover:text-fvyellow"
          onClick={submit}
        >
          {" "}
          Submit{" "}
        </button>
      </div>
    </div>
  );
};

const Home = () => {
  useEffect(() => {
    if (window.location.hash === "#community") {
      const element = document.getElementById("community");
      if (element) {
        element.scrollIntoView();
      }
    }
  }, []);

  const [showF1, setShowF1] = useState(false);
  const [showF2, setShowF2] = useState(false);
  //   const [showF3, setShowF3] = useState(false);
  const [showF4, setShowF4] = useState(false);
  //   const [showTools, setShowTools] = useState(false);

  const [settings] = useState({
    arrows: false,
    dots: false,
    infinite: true,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 0,
    cssEase: "linear",
  });
  const logos = [logo_eth, logo_pol, logo_bin, logo_cron, logo_op, logo_arb];

  const mcUrl =
    "https://laosnetwork.us21.list-manage.com/subscribe/post?u=a8a30830e9abfb70305c78868&amp;id=e497a4be7c&amp;f_id=0022eee6f0";

  return (
    <>
      <div className="container mx-auto px-10 pb-0">
        {/* <!-- Hero --> */}
        <section>
          <div className="grid grid-cols-1 sm:grid-cols-3 place-items-center">
            <div className="col-span-2 pt-10 text-center sm:text-left">
              <h1 className="sm:w-3/4 pb-10 header-title text-3xl md:text-5xl leading-none lg:leading-tight">
                The Universal Layer-1 for Digital Assets Across All Blockchains.
              </h1>
              <div className="grid grid-cols-1 md:w-100 md:flex md:space-x-4 space-y-4 md:space-y-0 md:justify-start">
                <a
                  href="https://docs.laosnetwork.io/"
                  className="border bg-fvyellow border-fvyellow text-fvpurple px-8 py-3 rounded transition duration-300 ease-in-out hover:bg-transparent hover:text-fvyellow"
                >
                  Start Building
                </a>
                <a
                  href="https://testnet.apps.laosnetwork.io/"
                  className="border border-fvyellow text-fvyellow px-8 py-3 rounded transition duration-300 ease-in-out hover:bg-fvyellow hover:text-fvpurple"
                >
                  Try Bridgeless Minting Now
                </a>

                {/* <div className="relative m-0 p-0">
                        <span onClick={() => setShowTools(!showTools)} className="border border-fvyellow text-fvyellow px-8 py-3 rounded transition duration-300 ease-in-out hover:bg-fvyellow hover:text-fvpurple">Try LAOS now</span>
                        <div className={`menu-resources ${showTools ? "" : "hidden"} block absolute mt-0 ml-0 w-max top-[calc(100%+20px)] bg-fvlightgrey border border-fvpurple px-2 rounded z-50`}>
                            <div className="text-fvpurple flex  flex-col mb-1">
                                <div>
                                    <svg className="inline-block" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z"></path></svg>
                                    <a href="https://docs.laosnetwork.io/" className="inline-block ml-2 mt-1 text-base text-fvpurple">Bridgeless Minter</a>
                                </div>
                                <div>
                                    <svg className="inline-block" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z"></path></svg>
                                    <a href="https://docs.laosnetwork.io/" className="inline-block ml-2 mt-1 text-base text-fvpurple">Create Collection</a>
                                </div>
                                <div>
                                    <svg className="inline-block" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z"></path></svg>
                                    <a href="https://docs.laosnetwork.io/" className="inline-block ml-2 mt-1 text-base text-fvpurple">Asset Extender</a>
                                </div>
                                <div>
                                    <svg className="inline-block" stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M1 2.5A1.5 1.5 0 0 1 2.5 1h3A1.5 1.5 0 0 1 7 2.5v3A1.5 1.5 0 0 1 5.5 7h-3A1.5 1.5 0 0 1 1 5.5v-3zM2.5 2a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 1h3A1.5 1.5 0 0 1 15 2.5v3A1.5 1.5 0 0 1 13.5 7h-3A1.5 1.5 0 0 1 9 5.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zM1 10.5A1.5 1.5 0 0 1 2.5 9h3A1.5 1.5 0 0 1 7 10.5v3A1.5 1.5 0 0 1 5.5 15h-3A1.5 1.5 0 0 1 1 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3zm6.5.5A1.5 1.5 0 0 1 10.5 9h3a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1-1.5 1.5h-3A1.5 1.5 0 0 1 9 13.5v-3zm1.5-.5a.5.5 0 0 0-.5.5v3a.5.5 0 0 0 .5.5h3a.5.5 0 0 0 .5-.5v-3a.5.5 0 0 0-.5-.5h-3z"></path></svg>
                                    <a href="https://docs.laosnetwork.io/" className="inline-block ml-2 mt-1 text-base text-fvpurple">Token Faucet</a>
                                </div>  
                            </div> 
                        </div>
                    </div> */}
              </div>
            </div>
            <div className="">
              {/* <div id="3d-ball" className=""></div> */}
              <iframe
                title="3d-ball"
                height="300"
                scrolling="no"
                src="https://walrus-app-bzt6a.ondigitalocean.app/"
              ></iframe>
            </div>
          </div>
        </section>
        <section className="mt-12 mb-14 w-full">
          <Slider {...settings}>
            {logos.map((logo, index) => (
              <div key={index} className="container">
                <img src={logo} alt="logo" />
              </div>
            ))}
          </Slider>
        </section>

        <section className="mb-8">
          <div className="grid grid-cols-1 py-8 md:grid-cols-4 bg-fvlightgrey bg-opacity-10 first:rounded-l-2xl last:rounded-r-2xl text-lg">
            <div
              onClick={() => setShowF1(!showF1)}
              className="border-r-2 border-slate-300/20 py-4 flex flex-col justify-center place-items-center"
            >
              <div
                className={`${
                  showF1 ? "hidden" : ""
                } flex flex-col space-y-4 px-8 justify-center place-items-center feature-box-title`}
              >
                <div>
                  <img
                    src={icon_bridgeless}
                    className="w-[28px]"
                    alt="Bridgeless Minting"
                  />
                </div>
                <div className="text-center flex flex-row justify-center place-items-center">
                  <div>
                    Bridgeless <br /> Minting
                  </div>
                  <div className="w-[16px] ml-4">
                    <svg viewBox="0 0 24 14.15">
                      <path
                        d="m12 14.15-12-12L2.15 0 12 9.9 21.85.05 24 2.2 12 14.15Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  showF1 ? "" : "hidden"
                } flex flex-col space-y-4 px-2 justify-center place-items-center feature-box-text`}
              >
                <div className="text-sm leading-tight font-normal text-center">
                  Mint a huge number of assets in any EVM chain at next-to-zero
                  cost, trading them in markets such as OpenSea and MagicEden.
                </div>
              </div>
            </div>
            <div
              onClick={() => setShowF2(!showF2)}
              className="border-r-2 border-slate-300/20 py-4 flex flex-col justify-center place-items-center"
            >
              <div
                className={`${
                  showF2 ? "hidden" : ""
                } flex flex-col space-y-4 px-8 justify-center place-items-center feature-box-title`}
              >
                <div>
                  <img
                    src={icon_1M}
                    className="w-[28px]"
                    alt="Bridgeless Minting"
                  />
                </div>
                <div className="text-center flex flex-row justify-center place-items-center">
                  <div>
                    Mint hundreds of <br />
                    assets per second
                  </div>
                  <div className="w-[16px] ml-4">
                    <svg viewBox="0 0 24 14.15">
                      <path
                        d="m12 14.15-12-12L2.15 0 12 9.9 21.85.05 24 2.2 12 14.15Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  showF2 ? "" : "hidden"
                } flex flex-col space-y-4 px-2 justify-center place-items-center feature-box-text`}
              >
                <div className="text-sm py-4 leading-tight font-normal text-center">
                  Lightning-fast technology allows you to mint over hundred
                  assets per second, in Ethereum or any EVM chain.
                </div>
              </div>
            </div>
            {/* <div onClick={() => setShowF3(!showF3)} className="border-r-2 border-slate-300/20 py-4 flex flex-col justify-center place-items-center">
                  <div className={`${showF3 ? "hidden" : ""} flex flex-col space-y-4 px-8 justify-center place-items-center feature-box-title`}>
                        <div><img src={icon_RWA} className="w-[28px]" alt="Bridgeless Minting" /></div>
                        <div className="text-center flex flex-row justify-center place-items-center">
                            <div>RWA Tokenization</div>
                            <div className="w-[16px] ml-2">
                                <svg viewBox="0 0 24 14.15">
                                    <path d="m12 14.15-12-12L2.15 0 12 9.9 21.85.05 24 2.2 12 14.15Z" fill="currentColor"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                    <div className={`${showF3 ? "" : "hidden"} flex flex-col space-y-4 px-2 justify-center place-items-center feature-box-text`}>
                        <div className="text-sm py-4 leading-tight font-normal text-center">
                            Mass tokenization of all assets: finance, royalties, provenance, invoices, certification; all connected to the most mature blockchain ecosystems.
                        </div>
                    </div>
                </div> */}
            <div
              onClick={() => setShowF4(!showF4)}
              className="border-r-2 border-slate-300/20 py-4 flex flex-col justify-center place-items-center"
            >
              <div
                className={`${
                  showF4 ? "hidden" : ""
                } flex flex-col space-y-4 px-8 justify-center place-items-center feature-box-title`}
              >
                <div>
                  <img
                    src={icon_DAME}
                    className="w-[28px]"
                    alt="Bridgeless Minting"
                  />
                </div>
                <div className="text-center flex flex-row justify-center place-items-center">
                  <div>
                    Decentralized <br /> Metadata Extension
                  </div>
                  <div className="w-[16px] ml-4">
                    <svg viewBox="0 0 24 14.15">
                      <path
                        d="m12 14.15-12-12L2.15 0 12 9.9 21.85.05 24 2.2 12 14.15Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div
                className={`${
                  showF4 ? "" : "hidden"
                } flex flex-col space-y-4 px-2 justify-center place-items-center feature-box-text`}
              >
                <div className="text-sm py-4 leading-tight font-normal text-center">
                  Extend the metadata and utility of existing assets
                  permissionlessly, allowing them to be used in different
                  applications.
                </div>
              </div>
            </div>
            <div className="py-4 flex flex-col justify-center place-items-center">
              <div className="flex flex-col space-y-4 px-8 justify-center place-items-center">
                <div>
                  <img
                    src={icon_DL}
                    className="w-[28px]"
                    alt="Bridgeless Minting"
                  />
                </div>
                <div className="text-center flex flex-row justify-center place-items-center">
                  <div>
                    <a
                      className="hover:underline"
                      href={
                        process.env.PUBLIC_URL + "/downloads/LAOS_litepaper.pdf"
                      }
                    >
                      Download the <br /> Litepaper
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* Skip out of container for full-width      */}
      </div>

      <section className="mb-8">
        <div className="grid grid-cols-1 sm:grid-cols-5 py-4 space-y-8 sm:space-y-0 bg-fvlightgrey bg-opacity-10 justify-center place-items-center">
          <div className="section-title text-xl">Backed by</div>
          <div>
            <img src={logo_early} alt="Earlybird" />
          </div>
          <div>
            <img src={logo_target} alt="Target Global" />
          </div>
          <div>
            <img src={logo_adara} alt="Adara" />
          </div>
          <div>
            <img src={logo_4F} alt="4Founders" />
          </div>
        </div>
      </section>
      {/* Back into container */}
      <div className="container mx-auto px-10">
        {/* <!-- USE CASES --> */}
        <section id="section-usecases">
          <h2 className="section-title text-3xl md:text-4xl mb-5">Use Cases</h2>
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-3">
            {/* <!-- Use Cases Card for Games --> */}
            <div className="card-games p-6 rounded-lg border-colors">
              <h3 className="text-xl md:text-2xl card-title text-white-400 lg:w-3/4">
                Gaming
              </h3>
              <p className="text-m w-3/4 pb-16">
                Push new use-cases for web3 in gaming. Rather than being rare,
                collectible items, assets can be created en-masse, and gifted,
                rewarded, or sold to any player, without worrying about scaling
                issues. Dynamic data means asset can be evolved and upgraded by
                how it is used in-game, and is valued by utility, not
                speculation.&nbsp;
                <a
                  className="text-fvyellow hover:underline"
                  href="https://medium.com/laosnetwork/digital-asset-use-cases-for-2024-and-beyond-part-2-the-evolution-of-web3-in-gaming-e6274b005558"
                >
                  Read more...
                </a>
              </p>
            </div>
            {/* <!-- Use Cases Card for Collectibles --> */}
            <div className="card-collectibles p-6 rounded-lg border-colors">
              <img
                className="card-collectibles-cube"
                src={card_coll}
                alt="block2"
              />
              <h3 className="text-xl md:text-2xl card-title text-white-400 lg:w-3/4">
                NFT Collectibles
              </h3>
              <p className="text-m w-3/4 pb-16">
                Save money by minting dynamic NFT collections on the mature
                ecosystems, such as Ethereum and Polygon, at a fraction of the
                cost. All assets are regular ERC-721s, and tradable in regular
                marketplace such as Opensea. Breathe new life into existing
                collections by updating their metadata - works for new
                collections and all existing collections on EVM chains.&nbsp;
                <a
                  className="text-fvyellow hover:underline"
                  href="https://medium.com/laosnetwork/digital-asset-use-cases-for-2024-and-beyond-part-1-exploring-the-landscape-of-digital-934fa3b9fe4c"
                >
                  Read more...
                </a>
              </p>
            </div>
            {/* <!-- Use Cases Card for RWA --> */}
            <div className="card-rwa p-6 rounded-lg border-colors">
              <img className="card-rwa-cube" src={card_RWA} alt="block1" />
              <h3 className="text-xl md:text-2xl card-title text-white-400 lg:w-3/4">
                Real-World Assets
              </h3>
              <p className="text-m w-3/4 pb-16">
                Tokenize Real-World Assets at massive scale on Ethereum, without
                worrying about cost. From invoices to certificates, to shares to
                financial assets. Use LAOS dynamic data and consensus to ensure
                up-to-date and accurate representation; and connect assets
                seamlessly to the most mature DeFi ecosystems.&nbsp;
                <a
                  className="text-fvyellow hover:underline"
                  href="https://medium.com/laosnetwork/exploring-laos-interoperability-and-scalability-d4b266e1ae01"
                >
                  Read more...
                </a>
              </p>
            </div>
          </div>
        </section>
        {/* <!-- SKIP OUT OF CONTAINER FOR FULL_WIDTH IMAGE --> */}
      </div>
      <div className="flex justify-center align-bottom polkadot h-40 md:h-60 lg:h-96 w-auto -mt-8">
        <img
          className="object-scale-down object-bottom w-1/4 pb-10"
          src={logo_polka}
          alt="Polkadot"
        />
      </div>
      {/* <!-- BACK INTO CONTAINER --> */}
      <div className="container mx-auto px-10">
        <section className="mb-10">
          <div className="flex justify-center">
            <p className="w-3/4 text-center">
              LAOS is a fully open Layer 1 blockchain, decentralized via usage
              of its own native utility token, and deployed as a parachain
              within Polkadot. Built with the guidance of the Substrate Builders
              Program, LAOS will inherit the security of the Polkadot ecosystem
              from day 1. LAOS will replicate a part of Polkadot&apos;s
              architecture inside Polkadot itself, providing its own
              sharding-based scaling specialized in Digital Ownership.
            </p>
          </div>
        </section>
        <section id="section-roadmap" className="mb-20">
          <img
            className="sm:h-[100rem]  md:h-[130rem] lg:h-[150rem]  max-w-full mx-auto rounded-xl"
            src={roadmap}
            alt="roadmap"
          />
        </section>
        <section id="section-benefits" className="mb-20">
          <h2 className="section-title text-3xl md:text-4xl mb-5">Benefits</h2>
          <hr className="w-[15%] h-[2px] my-4 rounded border-0 hr-colored" />
          <div className="grid grid-cols-1 md:grid-cols-3  gap-3">
            <div className="bg-fvdarkpurple p-3 rounded-lg border-2 border-fvgreypurple">
              <img
                className="w-1/2"
                src={benefit_icon_scale}
                alt="Scalability"
              />
              <h3 className="text-2xl card-title text-fvorange w-3/4">
                Spend Less on Minting
              </h3>
              <p className="text-m">
                LAOS&apos;s minting fees are more than ten times lower than any
                other blockchain, yet assets still appear as regular NFTs in
                other chains. Save money without leaving the ecosystem of your
                choice.
              </p>
            </div>
            <div className="bg-fvdarkpurple p-3 rounded-lg border-2 border-fvgreypurple">
              <img
                className="w-1/2"
                src={benefit_icon_bridgeless}
                alt="Bridgeless"
              />
              <h3 className="text-2xl card-title text-fvorange w-3/4">
                Mint Assets in All Chains
              </h3>
              <p className="text-m">
                Seamless integration with all EVM chains from day one, and more
                to come in the future. Mint assets on other chains, such as
                Ethereum or Polygon,{" "}
                <span className="font-bold">without paying gas fees</span> for
                that chain
              </p>
            </div>
            <div className="bg-fvdarkpurple p-3 rounded-lg border-2 border-fvgreypurple">
              <img
                className="w-1/2"
                src={benefit_icon_interop}
                alt="Interoperability"
              />
              <h3 className="text-2xl card-title text-fvorange w-3/4">
                Cross-Application Utility
              </h3>
              <p className="text-m">
                Use LAOS{" "}
                <span className="font-bold">
                  Decentralized Asset Metadata Extension
                </span>{" "}
                to permissionlessly add new functionality to existing assets,
                enabling them to be used as desired in other applications or
                games.{" "}
              </p>
            </div>
          </div>
        </section>
        <section id="community" className="mb-20">
          <h2 className="section-title text-4xl mb-5">Join Our Community</h2>
          <hr className="w-[15%] h-[2px] my-4 rounded border-0 hr-colored" />
          <h5 className="text-xl py-3">Why join the community?</h5>
          <p className="mb-5">
            Our community is where{" "}
            <span className="font-bold text-fvyellow">developers</span> can
            learn how to
            <span className="font-bold text-fvyellow">
              {" "}
              build applications
            </span>{" "}
            on LAOS and
            <span className="font-bold text-fvyellow">
              {" "}
              apply for our upcoming grant program
            </span>
            , and where those interested in the{" "}
            <span className="font-bold text-fvyellow">LAOS token </span>
            can keep up to date with news regarding the crowdloan and token
            launch.
          </p>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            <div
              onClick={() => {
                window.location.href = "https://discord.gg/gZcxsJcdPy";
              }}
              className="hover:cursor-pointer flex flex-col p-7 rounded-lg border-2 border-fvgreypurple hover:border-fvyellow hover:bg-gradient-to-br from-fvdarkpurple to-fvpurple"
            >
              <div>
                <img
                  className="h-[70px] pb-5"
                  src={com_discord}
                  alt="Discord"
                />
              </div>
              <div className="text-2xl font-bold">Discord</div>
              <div className="text-m">Community</div>
            </div>
            <div
              onClick={() => {
                window.location.href = "https://twitter.com/laosnetwork";
              }}
              className="hover:cursor-pointer flex flex-col p-7 rounded-lg border-2 border-fvgreypurple hover:border-fvyellow hover:bg-gradient-to-br from-fvdarkpurple to-fvpurple"
            >
              <div>
                <img className="h-[70px] pb-5" src={com_x} alt="X" />
              </div>
              <div className="text-2xl font-bold">X.com</div>
              <div className="text-m">Announcements</div>
            </div>
            <div
              onClick={() => {
                window.location.href = "https://t.me/+qI2I19EI-VAzNGVk";
              }}
              className="hover:cursor-pointer flex flex-col p-7 rounded-lg border-2 border-fvgreypurple hover:border-fvyellow hover:bg-gradient-to-br from-fvdarkpurple to-fvpurple"
            >
              <div>
                <img className="h-[70px] pb-5" src={com_tele} alt="Telegram" />
              </div>
              <div className="text-2xl font-bold">Telegram</div>
              <div className="text-m">Community</div>
            </div>
            <div
              onClick={() => {
                window.location.href =
                  "https://www.linkedin.com/company/laosnetwork/";
              }}
              className="hover:cursor-pointer flex flex-col p-7 rounded-lg border-2 border-fvgreypurple hover:border-fvyellow hover:bg-gradient-to-br from-fvdarkpurple to-fvpurple"
            >
              <div>
                <img className="h-[70px] pb-5" src={com_LI} alt="LinkedIn" />
              </div>
              <div className="text-2xl font-bold">LinkedIn</div>
              <div className="text-m">Announcements</div>
            </div>
            <div
              onClick={() => {
                window.location.href = "https://github.com/freeverseio/laos";
              }}
              className="hover:cursor-pointer flex flex-col p-7 rounded-lg border-2 border-fvgreypurple hover:border-fvyellow hover:bg-gradient-to-br from-fvdarkpurple to-fvpurple"
            >
              <div>
                <img className="h-[70px] pb-5" src={com_git} alt="Github" />
              </div>
              <div className="text-2xl font-bold">Github</div>
              <div className="text-m">Developers</div>
            </div>
            <div
              onClick={() => {
                window.location.href = "https://www.youtube.com/@laosnetwork";
              }}
              className="hover:cursor-pointer flex flex-col p-7 rounded-lg border-2 border-fvgreypurple hover:border-fvyellow hover:bg-gradient-to-br from-fvdarkpurple to-fvpurple"
            >
              <div>
                <img className="h-[70px] pb-5" src={com_yt} alt="Youtube" />
              </div>
              <div className="text-2xl font-bold">Youtube</div>
              <div className="text-m">Tutorials & Guides</div>
            </div>
          </div>
          <h5 className="text-xl py-3 mt-2">Collaborate with us</h5>
          <p>
            Want to collaborate with LAOS?{" "}
            <a href="/collaborate/" className="font-bold text-fvyellow">
              Get in touch
            </a>{" "}
            to let us know how we can work together!
          </p>
        </section>
        {/* Signup */}

        {/* <!-- SKIP OUT OF CONTAINER FOR FULL_WIDTH IMAGE --> */}
      </div>
      <div className="bg-fvpurple w-auto mb-10">
        <div className="container mx-auto px-10">
          <div className="flex flex-row items-center">
            <div className="py-10">
              <h3 className="text-4xl font-bold">Subscribe</h3>
              <p>
                Stay informed about the project updates, and the upcoming token
                launch
              </p>
              <MailchimpSubscribe
                url={mcUrl}
                render={({ subscribe, status, message }) => (
                  <CustomForm
                    status={status}
                    message={message}
                    onValidated={(formData) => subscribe(formData)}
                  />
                )}
              />
              <p className="text-xs">
                By clicking &apos;Submit&apos; you accept LAOS&apos;
                <a
                  className="text-fvyellow hover:underline"
                  href={process.env.PUBLIC_URL + "/downloads/Disclaimer.pdf"}
                >
                  {" "}
                  Terms of Use
                </a>{" "}
                and
                <a
                  className="text-fvyellow hover:underline"
                  href={process.env.PUBLIC_URL + "/downloads/Privacy.pdf"}
                >
                  {" "}
                  Privacy Policy
                </a>
              </p>
            </div>
            <div>
              <img
                className="hidden sm:block ml-20 h-40 mix-blend-plus-lighter"
                src={planet}
                alt="planet"
              />
            </div>
          </div>
        </div>
      </div>
      {/* <!-- BACK INTO CONTAINER --> */}
      <div className="container mx-auto px-10">
        {/* VIDEOS */}
        <section id="bridgeless-minter" className="mb-20">
          <h2 className="section-title text-4xl mb-5">
            Bridgeless Minting &amp; the Universal Node
          </h2>
          <div className="aspect-video">
            <iframe
              title="youtube"
              className="w-full h-full"
              src="https://www.youtube.com/embed/rBEYpxq63tI"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </section>

        {/* MOre stuff */}

        {/* End container */}
      </div>
    </>
  );
};

export default Home;
