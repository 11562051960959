/* eslint-disable react/prop-types */ 
import React from 'react';
import { Outlet } from "react-router-dom";
import Footer from "./Footer";
import Header from "./Header";
import {useLocation} from "react-router-dom";

const Layout = () => {

  let location = useLocation();
  console.log(location.pathname);

  return (
    <>
    <div className="bg-fvdarkpurple text-white background-image">
      <div className={location.pathname !== "/grant-program/" ? "background-sun p-0 m-0" : "p-0 m-0"}>
        <Header />
        <Outlet />
      </div>
      <Footer />
    </div>
    </>
  )
};

export default Layout;