// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.HomeBanner_banner__Y2g3W {
  background-color: rgba(215, 255, 94, 1);
  color: rgba(97, 61, 147, 1);
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0.7rem;
  text-align: center;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  max-height: 56px;
  z-index: 10;
  text-decoration: underline;
}

.HomeBanner_banner__Y2g3W:hover {
  background-color: rgba(196, 234, 53, 1);
  color: rgba(76, 47, 128, 1);
}



@media screen and (max-width: 500px) {
  .HomeBanner_banner__Y2g3W {
    font-size: 0.8rem;
    text-wrap: balance;
    text-decoration: underline;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/HomeBanner.module.css"],"names":[],"mappings":"AAAA;EACE,uCAAuC;EACvC,2BAA2B;EAC3B,cAAc;EACd,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,eAAe;EACf,kBAAkB;EAClB,aAAa;EACb,WAAW;EACX,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,WAAW;EACX,0BAA0B;AAC5B;;AAEA;EACE,uCAAuC;EACvC,2BAA2B;AAC7B;;;;AAIA;EACE;IACE,iBAAiB;IACjB,kBAAkB;IAClB,0BAA0B;EAC5B;AACF","sourcesContent":[".banner {\n  background-color: rgba(215, 255, 94, 1);\n  color: rgba(97, 61, 147, 1);\n  display: block;\n  position: fixed;\n  top: 0;\n  left: 0;\n  right: 0;\n  padding: 0.7rem;\n  text-align: center;\n  display: flex;\n  gap: 0.5rem;\n  align-items: center;\n  justify-content: center;\n  max-height: 56px;\n  z-index: 10;\n  text-decoration: underline;\n}\n\n.banner:hover {\n  background-color: rgba(196, 234, 53, 1);\n  color: rgba(76, 47, 128, 1);\n}\n\n\n\n@media screen and (max-width: 500px) {\n  .banner {\n    font-size: 0.8rem;\n    text-wrap: balance;\n    text-decoration: underline;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"banner": `HomeBanner_banner__Y2g3W`
};
export default ___CSS_LOADER_EXPORT___;
